

























































import Vue from 'vue';

import AdminSection from '../components/AdminSection.vue';
import DnbIcon from '../components/DnbIcon.vue';

export default Vue.extend({
  name: 'landing',
  components: { AdminSection, DnbIcon },
});
